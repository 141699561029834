import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Link } from "react-router-dom";
import { PryButton, CustomButton } from "../../../components/Buttons/Buttons";
import { ReactComponent as Play } from "../../../assets/svg/play.svg";
import "./banner.scss";

const Banner = () => {
  return (
    <section className="banner">
      <div className="banner-wrapper desktop-max-width">
        <AnimationOnScroll animateIn="animate__fadeIn">
          <div className="b-row-one">
            Oval <span>Finance</span>
          </div>

          <div className="b-row-two">
            The most comprehensive gateway to alternative financial services for
            fintechs, institutions, and enterprises across Africa.
          </div>

          <div className="b-row-three">
            <div>
              <Link to="/contact-us">
                <PryButton text="Learn More" />
              </Link>
            </div>

            <div>
              <Link to="/schedule-demo">
                <CustomButton
                  content={
                    <React.Fragment>
                      <Play className="play-icon" />
                      Request Demo
                    </React.Fragment>
                  }
                />
              </Link>
            </div>
          </div>
        </AnimationOnScroll>
      </div>
    </section>
  );
};

export default Banner;
