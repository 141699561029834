import React, { useEffect } from "react";
import "./getsupport.scss";
import SupportHeader from "./SupportHeader/SupportHeader";
import SupportContent from "./SupportContent/SupportContent";

const GetSupport = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  return (
    <section className="support-container">
      <SupportHeader />
      <SupportContent />
    </section>
  );
};

export default GetSupport;
