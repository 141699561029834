import MailIcon from "../assets/svg/headphone-icon.svg";
import HeadphoneIcon from "../assets/svg/headphone-icon.svg";
import HelpIcon from "../assets/svg/help-icon.svg";
import EmailIcon from "../assets/svg/mail-icon-2.svg";
import TelephoneIcon from "../assets/svg/telephone-icon.svg";
import LocationIcon from "../assets/svg/location-icon.svg";
import Twitter from "../assets/svg//twitter.svg";
import Facebook from "../assets/svg/facebook.svg";
import Linkedin from "../assets/svg/linkedin.svg";
export const SHOWN_PRIVACY_CONSENT_MODAL = {
  label: "shownPrivacyConsentModal",
  value: "true"
};
export const GET_SUPPORT_PAGE_ITEMS = [
  {
    Icon: HeadphoneIcon,
    title: "Get Support",
    content:
      "In need of assistance? Use this form to reach out and share your concerns our team will promptly address them."
  },
  {
    Icon: MailIcon,
    title: "Contact Us",
    content:
      "Connect with us directly for inquiries, assistance, or more information on our products and services."
  },
  {
    Icon: HelpIcon,
    title: "Help Desk",
    content:
      "Access our comprehensive support portal for solutions and assistance with technical issues, troubleshooting, and more."
  }
];
export const CONTACT_PAGE_INFORMATION = [
  {
    icon: EmailIcon,
    title: "Email Inquiries:",
    content: "hello@ovalfi.com"
  },
  {
    icon: LocationIcon,
    title: "Address:",
    content: "8 The Green, Ste A, Dover, DE 19901"
  },
  {
    icon: TelephoneIcon,
    title: "Contact by phone:",
    content: "+234 901 234 5678"
  }
];
export const COMPANY_SOCIAL_INFORMATION = [
  {
    icon: Facebook,
    title: "Facebook",
    url: "facebook.com"
  },
  {
    icon: Twitter,
    title: "Twitter",
    url: "twitter.com"
  },
  {
    icon: Linkedin,
    title: "Linkedin",
    url: "https://www.linkedin.com/company/ovalfi"
  }
];
