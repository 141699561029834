import React from "react";
import "./contactsection.scss";
import ContactForm from "../ContactForm/ContactUsForm";
import {
  COMPANY_SOCIAL_INFORMATION,
  CONTACT_PAGE_INFORMATION
} from "../../../utils/constants";
const ContactSection = () => {
  function handleClickAction(info) {
    window.open(info.url);
  }
  return (
    <section className="section-container">
      <div className="contact-form">
        <ContactForm />
      </div>
      <div className="contact-information">
        <div className="contact-information-wrapper">
          <p className="header-text">Contact Information</p>
          <p className="content-text">
            Fill out the form to reach out to our sales staff. Please contact us
            at <span>hello@ovalfi.com </span>if you have any general questions.
          </p>
        </div>
        <div className="contact-info-container">
          {CONTACT_PAGE_INFORMATION.map((info, index) => (
            <div className="contact-info" key={index}>
              <div className="info-icon">
                <img src={info.icon} alt={info.title} />
              </div>
              <div>
                <p className="info-title">{info.title}</p>
                <p className="info-content">{info.content}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="social-links">
          {COMPANY_SOCIAL_INFORMATION.map((info, index) => (
            <div className="social-info" key={index}>
              <div className="social-icon">
                {" "}
                <img
                  src={info.icon}
                  alt={info.title}
                  onClick={() => handleClickAction(info)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
