import React from "react";
const PrivacyContent = () => {
  return (
    <p>
      <span>
        At Oval, your privacy is our top priority. We handle your personal
        information with the utmost care, using it solely to enhance your
        experience on our platform. We want to assure you that safeguarding your
        data privacy is paramount to us.
      </span>
      <span>
        By continuing to use our platform, you implicitly consent to the
        processing of your personal data by Oval, its subsidiaries, and trusted
        partners. We strictly adhere to guidelines and other relevant data
        privacy regulations, ensuring your data is handled responsibly and in
        full compliance with the law.
      </span>
    </p>
  );
};
export default PrivacyContent;
