import React from "react";
import { useNavigate } from "react-router-dom";
import "./supportcontent.scss";
import { GET_SUPPORT_PAGE_ITEMS } from "../../../utils/constants";

const SupportHeader = () => {
  const navigate = useNavigate();
  function handleClickAction(item) {
    if (item.title === "Get Support") {
      navigate("/contact-form");
    } else if (item.title === "Contact Us") {
      window.open("mailto:hello@ovalfi.com", "_self");
    } else if (item.title === "Help Desk") {
      window.open(
        "https://ovalfi.atlassian.net/servicedesk/customer/portals",
        "_blank"
      ); // Open link in a new tab
    }
  }

  return (
    <section>
      <div className="content-container">
        {GET_SUPPORT_PAGE_ITEMS.map((item, index) => (
          <div
            key={index}
            className="content-box"
            onClick={() => handleClickAction(item)}
          >
            <img src={item.Icon} alt={item.title} className="support-icon" />
            <h3 className="support-title">{item.title}</h3>
            <p className="support-content">{item.content}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default SupportHeader;
