import React from "react";
import "./supportheader.scss";
const SupportHeader = () => {
  return (
    <section className="support-header">
      <div className="header-text">
        <div className="rect-image"></div>
        <p className="main-text">How can we help?</p>
        <p className="secondary-text">
          Get in touch with us, brush up on features and workflows, or
          troubleshoot an issue.
        </p>
      </div>
    </section>
  );
};

export default SupportHeader;
