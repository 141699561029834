const CookieContent = ({ open, geolocationStatus, nigerianUser }) => {
  return (
    <p className="cookie-content">
      We use cookies to give you a better and more inclusive banking experience.
      We only use your information{" "}
      {geolocationStatus === "successful" && (
        <span>
          in accordance with <span>{nigerianUser ? "N" : "G"}</span>DPR and
          other applicable regulations,{" "}
        </span>
      )}
      as explained in our{" "}
      <b className="cookie-content-span" onClick={open}>
        Cookie Policy
      </b>
      .
    </p>
  );
};

export default CookieContent;
