import React, { useState, useEffect } from "react";
// icons
import { ReactComponent as ShieldIcon } from "../../assets/svg/shield-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/svg/close-icon.svg";
import { ReactComponent as CookieIcon } from "../../assets/svg/cookie-icon.svg";
import PrivacyContent from "./PrivacyContent";
import CookieContent from "./CookieContent";
import CustomisePreferenceModal from "./CustomizePreference";

import { PryButton } from "../Buttons/Buttons";

// css
import "./privacy-consent-modal.scss";
const PrivacyConsentModal = ({
  closePrivacyConsentModal,
  cookieAction,
  nigerianUser,
  geolocationStatus
}) => {
  const [activeBar, setActiveBar] = useState("privacy-content");
  const [isPrivacyContent, setIsPrivacyContent] = useState(false);
  const [showCustomisePreferenceModal, setShowCustomisePreferenceModal] =
    useState(false);

  // functions
  const closeCustomisePreferenceHandler = () => {
    setShowCustomisePreferenceModal(false);
  };
  const closeHandler = () => {
    setShowCustomisePreferenceModal(false);
    closePrivacyConsentModal();
  };
  const openCustomisePreferenceModal = () => {
    setShowCustomisePreferenceModal(true);
  };
  const acceptAllHandler = () => {
    const payload = { privacyPolicy: true, cookiePolicy: true };
    acceptHandler(payload);
    closePrivacyConsentModal();
  };
  const declineAllHandler = () => {
    const payload = { privacyPolicy: false, cookiePolicy: false };
    acceptHandler(payload);
    closePrivacyConsentModal();
  };
  const acceptHandler = (payload) => {
    cookieAction(payload);
  };
  const activeClass = (bar) => (bar === activeBar ? "active" : "");

  const toggleActiveBar = (bar) => {
    setActiveBar(bar);
  };
  // use effect
  useEffect(() => {
    const result = activeBar === "privacy-content";
    setIsPrivacyContent(result);
  }, [activeBar]);
  return (
    <div>
      <div className="main">
        {/* top */}
        <div className="top">
          {isPrivacyContent ? <ShieldIcon /> : <CookieIcon />}
          <h2>{isPrivacyContent ? "Privacy Consent" : "Cookie Policy"}</h2>
          <CloseIcon
            onClick={closePrivacyConsentModal}
            className="close-icon"
          />
        </div>
        {/* toggler */}
        <div className="toggler">
          <h4
            className={`${activeClass("privacy-content")}`}
            onClick={() => toggleActiveBar("privacy-content")}
          >
            Privacy Consent
          </h4>
          <h4
            className={`${activeClass("cookie")}`}
            onClick={() => toggleActiveBar("cookie")}
          >
            Cookie Policy
          </h4>
        </div>
        {/* text */}
        {isPrivacyContent ? (
          <PrivacyContent />
        ) : (
          <CookieContent
            open={openCustomisePreferenceModal}
            nigerianUser={nigerianUser}
            geolocationStatus={geolocationStatus}
          />
        )}
        {/* buttons */}
        <div className="buttons">
          <div className="buttons-inner">
            <button className="decline-button" onClick={declineAllHandler}>
              Decline All
            </button>
            <button
              className="customise-button"
              onClick={openCustomisePreferenceModal}
            >
              Customise
            </button>
            <PryButton
              className="pry-button"
              text="Accept All"
              click={acceptAllHandler}
            />
          </div>
        </div>
      </div>
      {/* custom preference modal */}
      {showCustomisePreferenceModal && (
        <CustomisePreferenceModal
          closeHandler={closeHandler}
          closePreferenceHandler={closeCustomisePreferenceHandler}
          acceptHandler={acceptHandler}
        />
      )}
    </div>
  );
};

export default PrivacyConsentModal;
