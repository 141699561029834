import React, { useEffect } from "react";
import "./contactus.scss";
import Header from "./Header/Header";
import ContactSection from "./ContactSection/ContactSection";

const GetSupport = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  return (
    <section className="container">
      <Header />
      <ContactSection />
    </section>
  );
};

export default GetSupport;
