import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { PryButton, SecButton } from "../Buttons/Buttons";
import { ReactComponent as Logo } from "../../assets/svg/logo.svg";
import { ReactComponent as ArrowDown } from "../../assets/svg/arrow-down.svg";
// import { ReactComponent as About } from "../../assets/svg/navigation/about.svg";
// import { ReactComponent as Blog } from "../../assets/svg/navigation/blog.svg";
import { ReactComponent as Careers } from "../../assets/svg/navigation/careers.svg";
// import { ReactComponent as Channel } from "../../assets/svg/navigation/channel.svg";
import { ReactComponent as Contact } from "../../assets/svg/navigation/contact.svg";
// import { ReactComponent as Credit } from "../../assets/svg/navigation/credit.svg";
import { ReactComponent as Developer } from "../../assets/svg/navigation/developer.svg";
import { ReactComponent as Documentation } from "../../assets/svg/navigation/documentation.svg";
// import { ReactComponent as Medium } from "../../assets/svg/navigation/medium.svg";
// import { ReactComponent as Omni } from "../../assets/svg/navigation/omni.svg";
// import { ReactComponent as Terminal } from "../../assets/svg/navigation/terminal.svg";
// import { ReactComponent as Treasury } from "../../assets/svg/navigation/treasury.svg";
import "./navbar.scss";

const links = {
  // product: [
  //   {
  //     icon: <Treasury />,
  //     title: "Treasury",
  //     description:
  //       "High-yield USD-denominated interest on local currency deposits.",
  //     internal: true,
  //     url: "/treasury",
  //   },
  //   {
  //     icon: <Terminal />,
  //     title: "Terminal",
  //     description:
  //       "Execute multi-currency transfers, payouts, and collections.",
  //     internal: true,
  //     url: "/terminal",
  //   },
  //   {
  //     icon: <Channel />,
  //     title: "Channel",
  //     description:
  //       "Virtual/physical USD debit card issuance and digital dollar accounts",
  //     internal: true,
  //     url: "/channel",
  //   },
  //   {
  //     icon: <Omni />,
  //     title: "Omni",
  //     description:
  //       "Enable customers to accept crypto and fiat payments, share payment links, and automate invoices.",
  //     internal: true,
  //     url: "#",
  //   },
  //   {
  //     icon: <Medium />,
  //     title: "Medium",
  //     description: "Institutional grade digital asset investing and staking.",
  //     internal: true,
  //     url: "#",
  //   },
  //   {
  //     icon: <Credit />,
  //     title: "Credit",
  //     description: "SME credit and working capital financing platform",
  //     internal: true,
  //     url: "#",
  //   },
  // ],
  company: [
    // {
    //   icon: <About />,
    //   title: "About Us",
    //   description:
    //     "The most comprehensive gateway to alternative finance for institutions and fintechs.",
    //   internal: true,
    //   url: "/about-us",
    // },
    {
      icon: <Careers />,
      title: "Careers",
      description: "Build with us!",
      internal: false,
      url: "https://ovallabs.seamlesshiring.com/"
    },
    {
      icon: <Contact />,
      title: "Contact us",
      description: "Reach out with any inquiries.",
      internal: true,
      url: "/contact-us"
    }
    // {
    //   icon: <Blog />,
    //   title: "Blog",
    //   description: "",
    //   internal: false,
    //   url: "https://medium.com/@ovalfi",
    // },
  ],
  developers: [
    {
      icon: <Documentation />,
      title: "Documentation",
      description: "",
      internal: false,
      url: "https://docs.ovalfi.com/docs"
    },
    {
      icon: <Developer />,
      title: "Developer Hub",
      description: "",
      internal: false,
      url: "https://ovalfidevszone.slack.com/join/shared_invite/zt-1el4bv1oy-JdGchCypPlIrf9TPjsgrzg#/shared-invite/email"
    }
  ]
};

export default function Navbar({ showMobileMenu, setShowMobileMenu, theme }) {
  const [showDropdown, setShowDropdown] = useState(true);
  const location = useLocation();

  useEffect(() => {
    toggleDropdown();
  }, [location.pathname]);

  const toggleDropdown = () => {
    setShowDropdown(false);

    setTimeout(() => {
      setShowDropdown(true);
    }, 5);
  };

  return (
    <section className={`nav-container ${theme === "light" ? "light" : ""}`}>
      <div className="wrapper desktop-max-width">
        <div className="logo">
          <Link to="/">
            <Logo />
          </Link>
        </div>

        <div className="nav-center no-select">
          {/* <div className="n-c-item">
            <div className="n-c-i-el">
              Product <ArrowDown />
            </div>

            {showDropdown && (
              <div className="n-c-i-dropdown">
                <div className="d-inner-wrapper">
                  {links.product.map((item, index) => (
                    <NavDropdownItem item={item} key={index} />
                  ))}
                </div>
              </div>
            )}
          </div> */}

          <div className="n-c-item">
            <div className="n-c-i-el">
              Company <ArrowDown />
            </div>

            {showDropdown && (
              <div className="n-c-i-dropdown">
                <div className="d-inner-wrapper">
                  {links.company.map((item, index) => (
                    <NavDropdownItem item={item} key={index} />
                  ))}
                </div>
              </div>
            )}
          </div>

          <div className="n-c-item">
            <div className="n-c-i-el">
              Developers <ArrowDown />
            </div>

            {showDropdown && (
              <div className="n-c-i-dropdown">
                <div className="d-inner-wrapper smaller">
                  {links.developers.map((item, index) => (
                    <NavDropdownItem item={item} key={index} />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="nav-btns no-select">
          <div>
            <a
              href="https://app.ovalfi.com/login"
              target="_blank"
              rel="noreferrer"
            >
              <SecButton text="Login" />
            </a>
          </div>
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://app.ovalfi.com/sign-up"
            >
              <PryButton text="Get Started" />
            </a>
          </div>
        </div>

        <div
          className="hamburger-menu"
          onClick={() => {
            setShowMobileMenu(!showMobileMenu);
          }}
        >
          <div
            className={`hamburger ${showMobileMenu ? "open" : ""} ${
              theme === "light" ? "light" : ""
            }`}
          >
            <span className={`${theme === "light" ? "light" : ""}`}></span>
            <span className={`${theme === "light" ? "light" : ""}`}></span>
            <span className={`${theme === "light" ? "light" : ""}`}></span>
          </div>
        </div>

        <MobileNav
          showMobileMenu={showMobileMenu}
          setShowMobileMenu={setShowMobileMenu}
        />
      </div>
    </section>
  );
}

const MobileNav = ({ showMobileMenu, setShowMobileMenu }) => {
  const [active, setActive] = useState("");

  useEffect(() => {
    setActive("");
  }, [showMobileMenu]);

  return (
    <div className={`mobile-menu ${showMobileMenu ? "" : "off"}`}>
      <div className={showMobileMenu ? "wrapper-mobile on" : "wrapper-mobile"}>
        <ul className="nav-links-mobile">
          <li className="mobile-links">
            {/* <div
              className={`m-l-item click ${
                active === "product" ? "active-i" : ""
              }`}
              onClick={() => {
                if (active === "product") {
                  setActive("");
                } else {
                  setActive("");

                  setTimeout(() => {
                    setActive("product");
                  }, 1);
                }
              }}
            >
              Product <ArrowDown />
            </div> */}

            <div
              className={`m-l-item click ${
                active === "company" ? "active-i" : ""
              }`}
              onClick={() => {
                if (active === "company") {
                  setActive("");
                } else {
                  setActive("");

                  setTimeout(() => {
                    setActive("company");
                  }, 1);
                }
              }}
            >
              Company <ArrowDown />
            </div>

            <div
              className={`m-l-item click ${
                active === "developers" ? "active-i" : ""
              }`}
              onClick={() => {
                if (active === "developers") {
                  setActive("");
                } else {
                  setActive("");

                  setTimeout(() => {
                    setActive("developers");
                  }, 1);
                }
              }}
            >
              Developers <ArrowDown />
            </div>
          </li>

          {active && (
            <div className="m-d-wrapper">
              <div className="m-dropdown">
                {links[active].map((item, index) => (
                  <NavDropdownItem
                    key={index}
                    item={item}
                    isMobile
                    setShowMobileMenu={setShowMobileMenu}
                  />
                ))}
              </div>
            </div>
          )}

          <li style={{ marginTop: 30 }}>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://app.ovalfi.com/login"
            >
              <SecButton text="Login" />
            </a>
          </li>

          <li
            onClick={() => {
              setShowMobileMenu(!showMobileMenu);
            }}
          >
            <a
              target="_blank"
              rel="noreferrer"
              href="https://app.ovalfi.com/sign-up"
            >
              <PryButton text="Get Started" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

const NavDropdownItem = ({ item, isMobile, setShowMobileMenu }) => {
  const navigate = useNavigate();

  const { icon, title, description, internal, url } = item;

  return (
    <div
      className={`d-r-item click ${!description ? "align-center" : ""}`}
      onClick={() => {
        if (internal) {
          navigate(url);
          if (isMobile) {
            setShowMobileMenu(false);
          }
        } else {
          window.open(url, "_blank");
          if (isMobile) {
            setShowMobileMenu(false);
          }
        }
      }}
    >
      <div className="i-left">{icon}</div>

      <div className="i-right">
        <div className="i-r-title">{title}</div>

        {description && <div className="i-r-desc">{description}</div>}
      </div>
    </div>
  );
};
