import React, { useState, useEffect } from "react";
import Iframe from "react-iframe";
import "./scheduledemo.scss";

const ScheduleDemo = () => {
  const [iframeReady, setIframeReady] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setIframeReady(true);
    }, 2000);
    // eslint-disable-next-line
  }, []);
  return (
    <div className="schedule-demo">
      {!iframeReady && (
        <div className="page-loading">
          <lottie-player
            src="https://assets9.lottiefiles.com/packages/lf20_h1cidhml.json"
            background="transparent"
            speed="1"
            style={{ width: "200px", height: "200px" }}
            loop
            autoplay
          ></lottie-player>
        </div>
      )}

      <Iframe
        url="https://calendar.google.com/calendar/appointments/schedules/AcZssZ0vF9Vqdlmx6tQz-g3cCM9TWzSsKfM44hHMk1dSHx5vsWq42LtxkjEFVnozb4LWWjruVygCVVQv"
        id="myId"
        className="demo-container"
        display="initial"
        position="relative"
        frameBorder="0"
        scrolling={false}
        styles={{
          marginTop: "0px"
        }}
      />
    </div>
  );
};

export default ScheduleDemo;
