import React, { useState, useRef } from "react";
import { ReactComponent as CancelIcon } from "../../assets/svg/cancel-icon.svg";
import "./customise-preference.scss";

const CustomisePreferenceModal = ({
  closeHandler,
  acceptHandler,
  closePreferenceHandler
}) => {
  const mainRef = useRef();
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [cookiePolicy, setCookiePolicy] = useState(false);

  const declineHandler = () => {
    const payload = { privacyPolicy: false, cookiePolicy: false };
    acceptHandler(payload);
    closeHandler();
  };

  const savePreferenceHandler = () => {
    const payload = { privacyPolicy, cookiePolicy };
    acceptHandler(payload);
    closeHandler();
  };

  const acceptAllHandler = () => {
    const payload = { privacyPolicy: true, cookiePolicy: true };
    acceptHandler(payload);
    closeHandler();
  };
  const closeHandlerHandler = (event) => {
    if (event.target === mainRef.current) closePreferenceHandler();
  };

  return (
    <section
      className="main-section"
      ref={mainRef}
      onClick={closeHandlerHandler}
    >
      <section>
        {/* header */}
        <div className="header">
          <h3>Customise Preference</h3>
          <CancelIcon
            onClick={closePreferenceHandler}
            className="cancel-icon"
          />
        </div>
        {/* body */}
        <div className="body">
          {/* privacy policy */}
          <div className="mt-4">
            <h4 className="privacy-policy">Privacy Policy</h4>
            <p>
              At Oval Finance, your privacy is our top priority. We handle your
              personal information with the utmost care, using it solely to
              enhance your experience on our platform. We want to assure you
              that safeguarding your data privacy is paramount to us.
              <br />
              <br />
              By continuing to use our platform, you implicitly consent to the
              processing of your personal data by Oval Finance, its
              subsidiaries, and trusted partners. We strictly adhere to
              guidelines and other relevant data privacy regulations, ensuring
              your data is handled responsibly and in full compliance with the
              law.
            </p>
          </div>
          {/* cookie policy */}
          <div className="mt-9">
            <h4 className="mb-4 font-bold">Cookie Policy</h4>
            <div>
              Oval’s Cookie Policy When you visit our website, our website sends
              a cookie to your device. We use cookies to improve your experience
              while on our website. Our cookie policy explains what cookies are,
              the type of cookies we use and how we use them.What are Cookies?A
              cookie is a small text file that is sent to and stored in the
              browser directories of your device (smartphone or PC). Cookies can
              be used for a variety of purposes such as identifying users,
              keeping track of preferences and activity (e.g. Language,
              Currency, IP address used and Time zone)Types of Cookies and how
              we use them.
              <ul className="list-disc px-5 my-4">
                <li>
                  Session Cookies: are cookies that expire when you end the
                  session (leave the site or close your browser). Session
                  cookies are used to store certain information while you use
                  the site that makes navigation and your experience on our site
                  easier and more convenient.
                </li>
                <li>
                  Persistent Cookies: are cookies that remain stored on your
                  device until their expiry dates are exceeded or until they are
                  deleted. They are used to store preferences so that the
                  website can remember your choices the next time you access the
                  site. They are used to provide a more customized experience to
                  you whenever you access our website.
                </li>
              </ul>
              In addition to the above types, If you follow a link from our
              website to another website, please be aware that the owner of the
              other website will have their own privacy and cookie policies for
              their site.
              <br />
              <br />
              Please check the relevant third-party website for more information
              on their use of cookies.
              <br />
              <br />
              Cookie Preferences: Various browsers have different methods of
              managing cookies (including disabling options). <br />
              <br />
              However, you should note that some cookies are essential and
              disabling them may result in some functions or services not
              functioning properly.
              <br />
              <br />
              For more information on how to manage cookies, please refer to:
              <a
                href="https://www.aboutcookies.org"
                className="text-primary"
                target="_blank"
                rel="noreferrer"
              >
                aboutcookies.orgupdates
              </a>
              of this policy.
              <br />
              <br />
              We may update this policy from time to time.
              <br />
              <br />
              Kindly review this policy from time to time to ensure that you
              have up-to-date information.
            </div>
          </div>
        </div>
        {/* footer */}
        <div className="footer">
          <div className="check-container">
            <span className="text-[#5D5E68] font-semibold">Privacy Policy</span>
            <label htmlFor="myCheckbox1" className="checkbox-container">
              <input
                id="myCheckbox1"
                type="checkbox"
                checked={privacyPolicy}
                onChange={() => setPrivacyPolicy(!privacyPolicy)}
              />
              <div className="ball"></div>
            </label>
          </div>
          <div className="check-container">
            <span className="text-[#5D5E68] font-semibold">Cookie Policy</span>
            <label htmlFor="myCheckbox2" className="checkbox-container">
              <input
                id="myCheckbox2"
                type="checkbox"
                checked={cookiePolicy}
                onChange={() => setCookiePolicy(!cookiePolicy)}
              />
              <div className="ball"></div>
            </label>
          </div>
          {/* buttons */}
          <div className="buttons">
            <div
              id="secondary-btn"
              className="secondaryBtn decline-all"
              onClick={declineHandler}
            >
              Decline All
            </div>
            <div
              id="secondary-btn"
              className="secondaryBtn"
              onClick={savePreferenceHandler}
            >
              Save Preference
            </div>
            <div
              id="primary-btn"
              className="primaryBtn"
              onClick={acceptAllHandler}
            >
              Accept All
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default CustomisePreferenceModal;
