import React, { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { PryButton, SecButton } from "../../../components/Buttons/Buttons";

import "./contactusform.scss";

const ContactUsForm = () => {
  const [loading, setLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = (data) => {
    window.scrollTo(0, 0);

    let requestdata = {
      first_name: data.firstName,
      last_name: data.lastName,
      business_email: data.businessEmail,
      business_name: data.businessName,
      title: data.title,
      region: data.region,
      message: data.message,
      tenant: "oval"
    };

    setLoading(true);

    axios
      .post(
        "https://kngynmvk5pqcerv2wbdb5mmiuu0jfmjr.lambda-url.us-east-2.on.aws",
        requestdata,
        {
          headers: {
            Accept: "application/json"
          }
        }
      )
      .then(() => {
        setIsCompleted(true);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="contact-us">
      <div className="contact-us-wrapper desktop-max-width">
        <div className="form-content">
          {!isCompleted && !loading && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-row">
                <div className="form-item">
                  <label>First Name</label>
                  <input
                    placeholder="Enter first name"
                    {...register("firstName", { required: true })}
                  />
                  {errors.firstName && <p>First name is required.</p>}
                </div>

                <div className="form-item">
                  <label>Last Name</label>
                  <input
                    placeholder="Enter last name"
                    {...register("lastName", { required: true })}
                  />
                  {errors.lastName && <p>Last name is required.</p>}
                </div>
              </div>

              <div className="form-row">
                <div className="form-item">
                  <label>Business email</label>
                  <input
                    placeholder="Enter business email"
                    {...register("businessEmail", { required: true })}
                  />
                  {errors.businessEmail && <p>Business email is required.</p>}
                </div>

                <div className="form-item">
                  <label>Business Name</label>
                  <input
                    placeholder="Enter business name"
                    {...register("businessName", { required: true })}
                  />
                  {errors.businessName && <p>Business name is required.</p>}
                </div>
              </div>

              <div className="form-row">
                <div className="form-item">
                  <label>Title</label>
                  <input
                    placeholder="Enter Title"
                    {...register("title", { required: true })}
                  />
                  {errors.title && <p>Title is required.</p>}
                </div>

                <div className="form-item">
                  <label>Region</label>
                  <input
                    placeholder="Select region"
                    {...register("region", { required: true })}
                  />
                  {errors.region && <p>Region is required.</p>}
                </div>
              </div>

              <div className="form-row">
                <div className="form-item full">
                  <label>Message</label>
                  <textarea
                    placeholder="Type your message"
                    {...register("message", { required: true })}
                  />
                  {errors.message && <p>Message is required.</p>}
                </div>
              </div>
              {/* commented out because we are not displaying the privacy policy and terms of service */}
              {/* <div className="form-row check">
                <input
                  type="checkbox"
                  {...register("agree",)}
                />
                <div>
                  By submitting this form, I agree to Oval’s{" "}
                  <span>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </span>{" "}
                  and{" "}
                  <span>
                    <Link to="/terms-of-service">Terms of Service</Link>
                  </span>
                </div>
              </div> */}
              {/* 
              {errors.agree && (
                <p className="space-1">You must agree to continue.</p>
              )} */}

              <PryButton text="Submit" />
            </form>
          )}
        </div>

        {!isCompleted && loading && (
          <div className="is-loading">
            <lottie-player
              src="https://assets9.lottiefiles.com/packages/lf20_h1cidhml.json"
              background="transparent"
              speed="1"
              style={{ width: "200px", height: "200px" }}
              loop
              autoplay
            ></lottie-player>
          </div>
        )}

        {isCompleted && (
          <div className="c-u-completed">
            <div className="c-title">Thank you for contacting us!</div>

            <div className="c-desc">
              Your message has been sent successfully. You will receive a
              response as soon as possible.
            </div>

            <Link to="/">
              <SecButton text="Go back" />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactUsForm;
