import React from "react";
import "./header.scss";

const Header = () => {
  return (
    <section className="header">
      <div className="header-text">
        <p className="main-text">Get Support</p>
        <p className="secondary-text">
          Any enquiry or remark? Just send us a message
        </p>
      </div>
    </section>
  );
};

export default Header;
